<template>
  <div class="container">
    <el-card shadow="never" class="top-card">
      <p class="title">交易管理</p>
      <el-breadcrumb separator="/" class="bread">
        <el-breadcrumb-item>用户中心</el-breadcrumb-item>
        <el-breadcrumb-item>交易管理</el-breadcrumb-item>
      </el-breadcrumb>
      <el-tabs v-model="activeName" @tab-click="handleTabClick">
        <el-form
          ref="searchForm"
          :model="searchForm"
          inline
          label-width="80px"
          size="small"
        >
          <el-form-item label="订单编号" prop="platformOrderNo">
            <el-input
              style="width: 300px"
              v-model.trim="searchForm.platformOrderNo"
              placeholder="请输入订单编号"
            />
          </el-form-item>
          <el-form-item label="票号" v-if="activeName === '1'" prop="draftNo">
            <el-input
              style="width: 300px"
              v-model.trim="searchForm.draftNo"
              placeholder="请输入票号后六位"
            />
          </el-form-item>
          <el-form-item label="订单状态" prop="sts">
            <el-select
              v-model="searchForm.sts"
              placeholder="全部"
              clearable
              style="width: 300px"
            >
              <el-option
                v-for="item in statusList"
                :key="item.value"
                :value="item.value"
                :label="item.label"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="下单时间" prop="time">
            <el-date-picker
              type="daterange"
              placeholder="请选择"
              valueFormat="yyyy-MM-dd"
              format="yyyy-MM-dd"
              style="width: 300px"
              v-model="searchForm.time"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              style="width: 84px; margin-left: 80px"
              @click="_getTableList"
            >
              搜索
            </el-button>
            <el-button
              style="width: 84px"
              @click="$refs.searchForm.resetFields()"
            >
              重置
            </el-button>
          </el-form-item>
        </el-form>
        <el-tab-pane label="银票贴现" name="1" />
        <el-tab-pane label="商票闪融" name="2" />
      </el-tabs>
      <el-table
        v-loading="tableLoading"
        :data="tableData"
        stripe
        size="small"
        style="margin-top: 20px"
      >
        <el-table-column
          prop="platformOrderNo"
          label="订单编号"
          width="180"
        ></el-table-column>
        <el-table-column prop="draftNo" label="票号（后6位）" width="100">
          <template slot-scope="scope">
            {{ scope.row.draftNo.slice(-6) }}
          </template>
        </el-table-column>
        <el-table-column prop="bankAccName" label="贴现银行"></el-table-column>
        <el-table-column prop="accepterName" label="承兑人"></el-table-column>
        <el-table-column
          prop="maturityDate"
          label="票据到期日"
        ></el-table-column>
        <el-table-column width="120" label="贴现率（%）">
          <template slot-scope="scope">
            {{ formatRate(scope.row.discountRate) }}
          </template>
        </el-table-column>
        <el-table-column width="180" label="预计成交金额（元）">
          <template slot-scope="scope">
            {{ formatAmount(scope.row.expectDealAmount) }}
          </template>
        </el-table-column>
        <el-table-column prop="applyDate" label="下单时间" />
        <el-table-column width="190" label="状态">
          <template slot-scope="scope">
            {{ filterSts(scope.row.sts) }}
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              size="small"
              type="text"
              @click="
                $router.push(`/discount-detail/${scope.row.platformOrderNo}`)
              "
            >
              查看详情
            </el-button>
          </template>
        </el-table-column>
        <el-table-column label="贴凭">
          <template slot-scope="scope">
            <el-button
              size="small"
              type="text"
              @click="handleDownload(scope.row.platformOrderNo)"
            >
              下载贴凭
            </el-button>
          </template>
        </el-table-column>
        <el-table-column label="申请发票">
          <template slot-scope="scope">
            <el-button
              size="small"
              type="text"
              @click="handleApply(scope.row.bankCode)"
            >
              申请
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="page_btn_wrapper">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="searchForm.pageSize"
          @current-change="handleClickPageBtn"
        />
      </div>
    </el-card>
    <!--发票申请弹窗-->
    <el-dialog
      title="申请发票"
      width="912px"
      :visible.sync="dialog"
      :close-on-click-modal="false"
      @close="handleCloseDialog"
    >
      <div class="title">
        增值税发票信息
        <span>（与税务部门备案一致，所有信息必填）</span>
      </div>
      <el-form
        class="invoiceForm"
        ref="invoiceForm"
        :model="invoiceForm"
        :rules="invoiceFormRules"
        label-width="108px"
        size="small"
      >
        <el-row :gutter="80">
          <el-col :span="12">
            <el-form-item label="企业全称" prop="companyName">
              <el-input v-model="invoiceForm.companyName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="纳税人识别号"
              prop="taxpayerIdentificationNumber"
            >
              <el-input
                v-model="invoiceForm.taxpayerIdentificationNumber"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="80">
          <el-col :span="12">
            <el-form-item label="注册地址" prop="registerAddress">
              <el-input v-model="invoiceForm.registerAddress"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="电话号码" prop="phone">
              <el-input v-model="invoiceForm.phone"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="80">
          <el-col :span="12">
            <el-form-item label="开户银行" prop="bank">
              <el-input v-model="invoiceForm.bank"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="开户银行账号" prop="bankNum">
              <el-input v-model="invoiceForm.bankNum"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="title">邮寄地址</div>
      <el-form
        class="invoiceForm"
        ref="mailForm"
        :model="mailForm"
        :rules="mailFormRules"
        label-width="108px"
        size="small"
      >
        <el-row :gutter="80">
          <el-col :span="12">
            <el-form-item label="邮箱地址" prop="email">
              <el-input v-model="mailForm.email"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="btn_wrapper">
        <el-button
          size="small"
          style="margin-right: 20px; width: 80px"
          @click="dialog = false"
        >
          取消
        </el-button>
        <el-button
          type="primary"
          size="small"
          style="width: 80px"
          @click="handleSubmit"
        >
          确定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getTableList, getUrl } from './api'
import {
  getInvoiceDetail,
  saveInvoiceDetail,
} from 'views/user/account-center/api'
import numeral from 'numeral'

export default {
  data() {
    // 用于银票和商票订单状态的 select 选项
    this.silverStatusList = [
      {
        value: '1',
        label: '待银行确认',
      },
      {
        value: '2',
        label: '交易成功',
      },
      {
        value: '3',
        label: '交易失败',
      },
    ]
    this.commercialStatusList = [
      {
        value: '1',
        label: '待背书',
      },
      {
        value: '2',
        label: '审核中',
      },
      {
        value: '3',
        label: '签收异常',
      },
      {
        value: '4',
        label: '交易成功',
      },
      {
        value: '5',
        label: '交易失败',
      },
      {
        value: '6',
        label: '加保中',
      },
    ]

    // 用于银票和商票表格过滤 状态字段
    this.silverStatus = [
      { value: '0', label: '交易成功' },
      { value: '20', label: '待银行确认' },
      { value: '21', label: '待银行确认' },
      { value: '30', label: '交易失败' },
      { value: '91', label: '交易失败' },
      { value: '92', label: '交易失败' },
      { value: '93', label: '交易失败' },
    ]
    this.commercialStatus = [
      { value: '1', label: '加保中' },
      { value: '3', label: '待背书' },
      { value: '10', label: '待背书' },
      { value: '30', label: '审核中' },
      { value: '100', label: '交易成功' },
      { value: '99', label: '交易失败' },
      { value: '41', label: '签收异常' },
    ]
    const invoiceForm = {
      id: '',
      companyName: '',
      taxpayerIdentificationNumber: '',
      registerAddress: '',
      phone: '',
      bank: '',
      bankNum: '',
    }
    const invoiceFormRules = {}

    Object.keys(invoiceForm).forEach(key => {
      invoiceFormRules[key] = [
        { required: true, message: '输入框不能为空', trigger: 'blur' },
      ]
    })
    return {
      invoiceForm,
      invoiceFormRules,
      activeName: '1',
      searchForm: {
        platformOrderNo: '',
        draftNo: '',
        sts: '',
        time: '',
        receiptsType: '1',
        pageSize: 10,
        pageNum: 1,
      },
      total: 0,
      tableLoading: true,
      dialog: false,
      mailForm: {
        email: '',
      },
      mailFormRules: {
        email: [{ required: true, message: '输入框不能为空', trigger: 'blur' }],
      },
      tableData: [],
      statusList: this.silverStatusList,
    }
  },
  mounted() {
    this._getTableList()
  },
  methods: {
    // 格式化金额
    formatAmount(amount) {
      if (amount) {
        return numeral(amount).format('0,0.00')
      }
      return ''
    },
    handleTabClick({ name }) {
      this.$refs.searchForm.resetFields()
      this.searchForm.receiptsType = name
      this.statusList =
        name === '1' ? this.silverStatusList : this.commercialStatusList
      this._getTableList()
    },
    async _getInvoiceDetail() {
      try {
        const result = await getInvoiceDetail()
        Object.keys(result).forEach(key => {
          this.invoiceForm[key] = result[key]
        })
        this.mailForm.email = result.email
      } catch (err) {
        console.error(err)
      }
    },
    handleApply(bankCode) {
      if (bankCode === 'ZB') {
        // 众邦银行需要弹窗提交
        this._getInvoiceDetail()
        this.dialog = true
      } else {
        this.$router.push('/user/invoice-request')
      }
    },
    async handleDownload(orderNo) {
      try {
        const result = await getUrl(orderNo)

        if (result.responseCode === '000000') {
          window.open(result.url)
          console.log(result)
        } else {
          this.$message.error(result.responseDesc)
        }
      } catch (err) {
        console.error(err)
      }
    },
    formatRate(rate) {
      let r = rate && numeral(rate).format('0.00%')
      if (r) {
        r = r.substr(0, r.length - 1)
      }
      return r
    },
    filterSts(sts) {
      if (!sts) return sts
      const statusList =
        this.activeName === '1' ? this.silverStatus : this.commercialStatus
      const result = statusList.filter(item => item.value === sts)
      if (result && result.length) {
        return result[0].label
      }
    },
    async handleSubmit() {
      try {
        const invoiceValid = await this.$refs.invoiceForm.validate()
        const mailValid = await this.$refs.mailForm.validate()

        if (invoiceValid && mailValid) {
          const submitData = { ...this.invoiceForm, ...this.mailForm }
          await saveInvoiceDetail(submitData)
          this.$message.success('保存成功')
          this.dialog = false
        }
      } catch (err) {
        console.error(err)
      }
    },
    async _getTableList() {
      try {
        const submitData = { ...this.searchForm, startTime: '', endTime: '' }
        const { time } = submitData
        if (time && time.length) {
          submitData.startTime = time[0]
          submitData.endTime = time[1]
        }
        delete submitData.time
        this.tableLoading = true
        const { dataSet = [], totalNumber = 0 } = await getTableList(submitData)
        this.tableData = dataSet
        this.total = totalNumber
        this.tableLoading = false
      } catch (err) {
        console.error(err)
      }
    },
    handleClickPageBtn(page) {
      this.searchForm = {
        ...this.searchForm,
        pageNum: page,
      }
      this._getTableList()
    },
    handleCloseDialog() {
      this.$refs.invoiceForm.resetFields()
      this.$refs.mailForm.resetFields()
    },
  },
}
</script>

<style scoped lang="scss">
.container {
  background-color: #f6f8fc;
  box-sizing: border-box;
  padding: 16px 72px 16px 72px;

  .top-card {
    margin-bottom: 16px;
    height: 100%;

    .title {
      color: #333333;
      font-size: 16px;
      font-weight: 500;
    }

    .bread {
      color: #999999;
      font-size: 14px;
      margin-top: 10px;
      margin-bottom: 23px;
    }

    .btnArea {
      span {
        display: inline-block;
        width: 104px;
        height: 36px;
        background: #2c68ff;
        border-radius: 8px;
        line-height: 36px;
        text-align: center;
        color: white;
        margin-right: 16px;
        cursor: pointer;
      }
    }
  }

  .title {
    background: #f6f8fc;
    line-height: 40px;
    font-size: 14px;
    color: #333;
    padding-left: 16px;
    font-weight: 600;
    margin-bottom: 20px;

    span {
      font-size: 14px;
      color: #949393;
    }
  }

  .btn_wrapper {
    text-align: center;
    margin-top: 20px;
  }

  .page_btn_wrapper {
    text-align: right;
    padding-top: 20px;
  }
}
</style>
